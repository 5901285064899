import tippy, { Props } from "tippy.js";
import { getMap, parsePathCountryName } from "./modules/getCustomMap";
import { fillCountries } from "./fillCountries";
import { getLegend } from "./getLegend";

const TOOLTIP_HEIGHT = 50;

export let initialized = false;

let data = window.countryPopularity || [];
export let coloredCountries = [];

export async function initCustomMap() {
  if (!$("[data-popularity-map]").length) return;

  data = window.countryPopularity || [];
  const { countries, map } = await getMap();
  let legend = getLegend();
  coloredCountries = [];
  const avaliableCountries = data.map(({ code }) => code);

  // Paint map
  Object.entries(legend).forEach(([key, { color, minLogs }]) => {
    fillCountries(countries, { color, minLogs, code: key }, data);
  });

  // Repaint map on change theme
  initialized = true;
  $("#switch-theme").on("change", () => {
    legend = getLegend();
    coloredCountries = [];

    Object.entries(legend).forEach(([key, { color, minLogs }]) => {
      fillCountries(
        countries,
        {
          color,
          minLogs,
          code: key,
        },
        data
      );
    });
  });

  // Map tooltip
  let openedTooltipCountry = null;

  const tooltip = tippy(map, {
    content: "",
    placement: "right-start",
    trigger: "manual",
    arrow: false,
    offset: [0, 0],
    animation: "scale",
    allowHTML: true,
    onHide() {
      openedTooltipCountry = null;
    },
  });

  window.addEventListener("scroll", tooltip.hide);
  window.addEventListener("mousemove", ({ target }) => {
    if (!(target as HTMLElement).closest("path")) {
      tooltip.hide();
    }
  });

  map.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });

  map.addEventListener("mousemove", ({ target, clientX, clientY }) => {
    const path = (target as HTMLElement).closest("path");
    const country = parsePathCountryName(path?.getAttribute("class") || "");

    if (!country || !avaliableCountries.includes(country)) {
      return tooltip.hide();
    }

    const { title, description } =
      data.find(({ code }) => code === country) || {};

    if (!title || !description) {
      return tooltip.hide();
    }

    const rect = {
      width: 0,
      height: 0,
      top: clientY - TOOLTIP_HEIGHT,
      bottom: clientY,
      left: clientX,
      right: clientX,
    } as DOMRect;

    const props: Partial<Props> = {
      getReferenceClientRect: () => rect,
    };

    if (country !== openedTooltipCountry) {
      props.content = `
        <div class="text-center">
          <p>${title}</p>
          <p class="text-grey-300 mt-1">${description}</p>
        </div>
      `;
    }

    tooltip.setProps(props);

    if (country !== openedTooltipCountry) {
      tooltip.show();
    }

    openedTooltipCountry = country;
    return null;
  });
}
