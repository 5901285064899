export const tooltipTypes: { [type: string]: (el: HTMLElement) => string } = {
  changedIp(trigger: HTMLElement): string {
    const { title, oldIp, currentIp } = trigger.dataset;
    if (!oldIp || !currentIp) return "";
    const iconTpl = $("#icon-tpl");

    let icon = iconTpl.get(0).outerHTML;
    icon = icon.replace("iconName", "change");
    icon = icon.replace('id="icon-tpl"', "");
    icon = icon.replace("hidden", "");

    return `
         <div class="ip-tooltip">
           <p class="title">${title}</p>
             <div class="ip-inner">
               <span class="old">${oldIp}</span>
               ${icon}
               <span class="current">${currentIp}</span>
             </div>
         </div>
       `;
  },

  custom(trigger: HTMLElement): string {
    const content = trigger.dataset.tooltipJson || null;
    if (!content) return "";

    try {
      const { title, refs } = JSON.parse(content) as {
        title: string;
        refs: { key: string; value: string; url?: string }[];
      };

      let tpl = `
        <div class="w-72">
          <h2 class="text-xs uppercase">${title}</h2>
          <ul class="gsp-tooltip-refs text-danger leading-none text-xs mt-6">
      `;

      tpl += refs.reduce((acc, { key, value, url }) => {
        acc += `
          <li class="grid grid-cols-12 py-[7px]">
             <span class="col-span-4 text-grey-300 mr-2">${key}</span>
                ${
                  url
                    ? `<a class='col-span-8 text-primary underline' href="${url}">${value}</a>`
                    : ` <span class="col-span-8"> ${value}</$span>`
                }
          </li>
        `;
        return acc;
      }, "");

      tpl += "</ul</div>";
      return tpl;
    } catch (err) {
      return "";
    }
  },
};
