import gsap from "gsap";
import * as $ from "jquery";
export const MODAL_DURATION = 0.25;
export class Modal {
  private readonly duration: number;
  private onShow?: {
    id: string;
    cb: (target?: HTMLElement) => void;
  }[];
  private onClose?: {
    id: string;
    cb: (target?: HTMLElement) => void;
  }[];

  private onBeforeClose: {
    id: string;
    cb: (target?: HTMLElement) => void;
  }[];

  constructor() {
    this.duration = MODAL_DURATION;

    gsap.set($(".modal"), { autoAlpha: 0, pointerEvents: "none" });
    gsap.set($(".modal__window"), { autoAlpha: 0, scale: 1.4, y: -50 });

    this.onShow = [];
    this.onClose = [];
    this.onBeforeClose = [];
  }

  async open(id: string, target?: HTMLElement) {
    const _modal = $(id);
    const _window = _modal.find(".modal__window");
    const { duration } = this;

    this.onShow.find((handler) => handler.id === id)?.cb?.(target);

    gsap.to(_modal, {
      autoAlpha: 1,
      duration: duration,
    });

    gsap.to(_window, {
      autoAlpha: 1,
      scale: 1,
      yPercent: -50,
      duration,
      onComplete() {
        $(_modal).css({ pointerEvents: "initial" });
      },
    });
  }

  async close(id: string, onClose = () => {}) {
    const _modal = $(id);
    const _window = _modal.find(".modal__window");
    const { duration } = this;
    const cb = this.onClose.find((handler) => handler.id === id)?.cb;

    $(_modal).css({ pointerEvents: "none" });

    $(`[data-target="#${id}"]`).css({ pointerEvents: "none" });

    this.onBeforeClose.find((handler) => handler.id === id)?.cb?.();
    gsap.to(_window, {
      autoAlpha: 0,
      scale: 1.4,
      y: -50,
      duration,
    });

    gsap.to(_modal, {
      autoAlpha: 0,
      onComplete() {
        cb?.();
        $(`[data-target="#${id}"]`).css({ pointerEvents: "initial" });
        onClose();
      },
    });
  }

  on(
    id: string,
    evt: "onShow" | "onClose" | "onBeforeClose",
    cb: (target?: HTMLElement) => void
  ) {
    const exist = this[evt].find((handler) => {
      return handler.id === id;
    });

    if (exist) {
      exist.cb = cb;
      return;
    }

    this[evt].push({ id, cb });
  }
}

export function initModal() {
  const modal = new Modal();

  $("[data-modal-open]").each(function () {
    const { target } = this.dataset;
    if (!target) return;

    $(this).on("click", (evt) => {
      modal.open(target, evt.target);
    });
  });

  $("[data-modal-close]").each(function () {
    const _modal = $(this).parents(".modal");
    const id = _modal.attr("id");

    if (!id) return;

    $(this).on("click", () => {
      modal.close(`#${id}`);
    });
  });

  $(".modal").each(function () {
    const { closeOnBackdrop } = this.dataset;

    if (closeOnBackdrop !== "false") {
      const id = $(this).attr("id");

      $(this).on("click", (evt) => {
        if (!evt.target.closest(".modal__window")) {
          modal.close(`#${id}`);
        }
      });
    }
  });

  window.modal = modal;
  return modal;
}

$("[data-ajax-reinit-sensor]").on("change", () => {
  initModal();
});
