import { TLegend } from "./map.types";

export function getLegend() {
  return $("[data-map-legend-item]")
    .toArray()
    .reduce((acc, item) => {
      const { minLogs, key } = item.dataset;
      const color = $(item)
        .find("[data-legend-color]")
        .css("background-color");

      acc[key] = { minLogs: Number(minLogs), color };
      return acc;
    }, {} as TLegend);
}
