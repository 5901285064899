import $ from "jquery";
import { makeDelay } from "@scripts/utilles/delay";

const duration = 300;
export function switchTheme() {
  let theme = localStorage.getItem("theme");
  const $body = $("body");
  $body.addClass($body.get(0).dataset.transition);

  const $checkbox = $("[data-switch-theme-checkbox]");

  $checkbox.prop("checked", theme === "dark");
  localStorage.setItem("theme", theme);

  $checkbox.on("change", async () => {
    await makeDelay(duration);

    theme = $checkbox.prop("checked") ? "dark" : "light";

    $checkbox.prop("checked", theme === "dark");
    $("html").toggleClass("dark", theme === "dark");
    localStorage.setItem("theme", theme);
  });
}
