import * as $ from "jquery";
import { handleMapMove } from "./handleMapMove";
import { handleMapScale } from "./handleMapScale";

export type TCountry = {
  code: string;
  path: SVGPathElement;
};

export function parsePathCountryName(className: string): string | undefined {
  const [_, __, name] = className.split("_");
  return name?.toLocaleLowerCase();
}

export async function getMap() {
  try {
    // Fetch and parse image
    const svg = await $.get("/map.svg");
    const parsedSvg = new XMLSerializer().serializeToString(
      svg.documentElement
    );

    // Create Map
    const _map = document.createElement("div");
    $(_map).html(parsedSvg);

    const countries = $(_map)
      .find("path")
      .toArray()
      .reduce((acc, path) => {
        const name = parsePathCountryName(path.getAttribute("class"));
        acc.push({
          code: name.toLocaleLowerCase(),
          path,
        });

        return acc;
      }, []);

    // Render map
    const $mapParent = $("[data-popularity-map]").append($(_map).children());
    const $map = $mapParent.find("#map-svg");

    handleMapMove($map.get(0), $mapParent.get(0));
    handleMapScale($map.get(0), $mapParent.get(0), () => {});

    return {
      countries,
      map: $map.get(0),
      parent: $mapParent.get(0),
    } as {
      countries: TCountry[];
      map: HTMLElement;
      parent: HTMLElement;
    };
  } catch (err) {
    console.log("Map error ", err);
    return null;
  }
}
