import { mapTheme } from "./map.theme";
import * as $ from "jquery";

let init = false;

export interface IMapsListItem {
  target: HTMLElement;
  config: {
    center: { lat: number; lng: number };
    zoom?: number;
  };
}

export interface IUpdatedMapsListItem extends IMapsListItem {
  map: any;
}

const onMapInit: Array<() => void> = [];

function initGoogleMaps(key = "AIzaSyAnta80PBpNZZFGOYGJuFBt-UJvjF4W6Ok") {
  init = true;

  return new Promise((resolve) => {
    const script = document.createElement("script");

    script.src = `https://maps.googleapis.com/maps/api/js?key=${key}&callback=initMap`;
    script.async = true;

    window.initMap = () => {
      resolve("");
      onMapInit.forEach((fn) => fn());
    };

    document.head.appendChild(script);
  });
}

export async function createMap({ target, config }: IMapsListItem) {
  if (!init) initGoogleMaps();

  return new Promise((resolve) => {
    onMapInit.push(() => {
      const map = new window.google.maps.Map(target, {
        ...config,
        disableDefaultUI: true,
        zoomControl: true,
        fullscreenControl: true,
        styles: mapTheme,
      });

      const marker = new window.google.maps.Marker({
        position: config.center,
        map,
      });

      resolve({
        map,
        setCenter({ lat, lng }) {
          const center = new window.google.maps.LatLng(lat, lng);
          map.setCenter(center);
          marker.setPosition(center);
        },
      });
    });
  });
}

export function initMaps() {
  $("[data-google-map]").each(function () {
    const self = $(this);
    const { lat, lng } = self.data();
    if (!lat || !lng) return;

    createMap({
      target: this,
      config: {
        center: { lat, lng },
        zoom: 10,
      },
    });
  });
}
