import * as $ from "jquery";

const copy = (value: string) => {
  return navigator.clipboard.writeText(value);
};

export function copyButtons() {
  $(document.documentElement).on("click", function (evt) {
    const target = (evt.target as HTMLElement).closest("[data-copy-btn]");

    if (!target) return;
    const { value = "" } = $(target).data() as { value?: string };
    copy(value);
  });
}
