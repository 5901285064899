import * as $ from "jquery";
import gsap from "gsap";

const step = 1;
const minScale = 0.87;
const maxScale = 10;
let scale = minScale;

export function handleMapScale(
  map: HTMLElement,
  container?: HTMLElement,
  onScaleChangedCb?: Function
) {
  const root = container || map;

  function scaleMap(count: number) {
    gsap.to(map, {
      scale: count,
      duration: 0.3,
      onComplete() {
        $(map).trigger("resize", { scale: count });
      },
    });
  }
  // scale handle
  const buttons = $("[data-map-scale]");
  const increase = buttons.filter("[data-action='increase']");
  const decrease = buttons.filter("[data-action='decrease']");

  // Manage buttons opacity
  const onScaleChanged = () => {
    onScaleChangedCb();
    const isMax = scale >= maxScale;
    const isMin = scale <= minScale;

    increase.css({
      opacity: isMax ? 0.5 : 1,
      cursor: isMax ? "default" : "pointer",
    });

    decrease.css({
      opacity: isMin ? 0.5 : 1,
      cursor: isMin ? "default" : "pointer",
    });
  };

  onScaleChanged();
  increase.on("click", () => {
    if (scale <= maxScale - step) {
      scale += step;
    } else {
      scale = maxScale;
    }

    onScaleChanged();
    scaleMap(scale);
  });

  decrease.on("click", () => {
    if (scale >= minScale + step) {
      scale -= step;
    } else {
      scale = minScale;
    }

    onScaleChanged();
    scaleMap(scale);
  });
}
