import * as $ from "jquery";

export function initAutoGrowTextarea() {
  init();
  $("[data-ajax-reinit-sensor]").on("change", init);

  function init() {
    $("[data-autogrow]").each(function () {
      const textarea = $(this);
      const height = textarea.outerHeight();
      const borderWidth = parseInt(textarea.css("border-width"));

      function changeHandler() {
        textarea
          .outerHeight(height + borderWidth * 2)
          .outerHeight(this.scrollHeight);
      }

      textarea.on("input", changeHandler);
    });
  }
}
