import * as $ from "jquery";

const selected = "selected";
const firstInGroup = "first-in-group";
const lastInGroup = "last-in-group";

export function initiSelectRows() {
  init();
  $("[data-ajax-reinit-sensor]").on("change", init);

  function init() {
    $("[data-selectable]").each(function () {
      const self = $(this);
      const rows = self.find("[data-selectable-row]");
      const checks = rows.toArray().map((row) => {
        const checkbox = $(row).find("input[type=checkbox]");
        return { row, checkbox, checked: checkbox.prop("checked") };
      });

      const selectedRowActions = self.find("[data-selected-row-actions]");
      selectedRowActions.hide();

      function toggleButtons() {
        const action = checks.some(({ checked }) => checked)
          ? "fadeIn"
          : "fadeOut";

        selectedRowActions[action]("fast");
      }

      self.find("[data-select-all-rows]").on("change", ({ target }) => {
        const allSelected = $(target).prop("checked");
        rows.first().toggleClass(firstInGroup, allSelected);
        rows.last().toggleClass(lastInGroup, allSelected);

        checks.forEach((check) => {
          check.checked = allSelected;
          check.checkbox.prop("checked", allSelected);
          $(check.row)
            .toggleClass(selected, allSelected)
            .removeClass(firstInGroup)
            .removeClass(lastInGroup);
        });

        $(checks[0]?.row).toggleClass(firstInGroup, allSelected);
        $(checks[checks.length - 1]?.row).toggleClass(lastInGroup, allSelected);
        toggleButtons();
      });

      checks.forEach((check) => {
        check.checkbox.on("change", () => {
          check.checked = !check.checked;
          if (!check.checked) {
            self.find("[data-select-all-rows]").prop("checked", check.checked);
          }

          const checked = check.checked;
          const row = $(check.row);

          row.toggleClass(selected, checked);
          toggleButtons();

          rows.each(function (idx) {
            const self = $(this);
            const prev = self.prev();
            const next = self.next();
            const isSelected = checks[idx].checked;

            self.toggleClass(
              firstInGroup,
              isSelected && !prev.hasClass(selected)
            );

            self.toggleClass(
              lastInGroup,
              isSelected && !next.hasClass(selected)
            );
          });
        });
      });
    });
  }
}
