import * as $ from "jquery";
import tippy, { Props, Instance } from "tippy.js";
import "tippy.js/animations/scale.css";
import { makeDelay } from "../../utilles/delay";
import { tooltipTypes } from "./getTooltipByType";
import { tooltipData } from "./tooltip.types";
import { gpsTooltip } from "./special-cases/gps-tooltip";

let tooltips: Array<Instance> = [];

export function tooltipInit() {
  init();
  // special cases
  gpsTooltip();

  $("[data-ajax-reinit-sensor]").on("change", () => {
    tooltips.forEach((t) => {
      t.destroy();
    });

    tooltips = [];

    init();
  });

  function init() {
    // regular tooltips
    $("[data-tooltip]").each(function () {
      const trigger = $(this);

      const {
        tooltipContent = "",
        tooltipTrigger = "mouseenter focus",
        tooltipHtml = "",
        tooltipHideDelay,
        tooltipInteractive = true,
        tooltipType,
        placement,
      } = trigger.data() as tooltipData;

      let content = "";
      if (tooltipType) {
        if (!Object.keys(tooltipTypes).includes(tooltipType)) {
          console.error(
            "Tooltip type " + tooltipType + " does not exixt.",
            " Plese use one of " + Object.keys(tooltipTypes).join(",")
          );
        }
        content = tooltipTypes[tooltipType]?.(trigger.get(0));
      } else {
        if (tooltipContent) content = tooltipContent;
        if (tooltipHtml) content = tooltipHtml;
      }

      const tooltipPlacement = placement || "top-start";

      const options: Partial<Props> = {
        trigger: tooltipTrigger,
        animation: "scale",
        interactive: !!tooltipInteractive,
        allowHTML: !!tooltipHtml || !!tooltipType,
        placement: tooltipPlacement,

        offset({ reference }) {
          if (tooltipPlacement !== "top-start") return [0, 0];
          return [reference.width - 20, 0];
        },
        content,
        onShow(self) {
          if (tooltipHideDelay) {
            makeDelay(Number(tooltipHideDelay)).then(() => {
              self.hide();
            });
          }
        },
      };

      const t = tippy(trigger.get(0), options);
      tooltips.push(t);
    });
  }
}
