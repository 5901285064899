import { TCountry } from "./modules/getCustomMap";
import gsap from "gsap";
import { coloredCountries, initialized } from "./customMap";
import { CountryPopularity } from "./map.types";

export function fillCountries(
  countries: TCountry[],
  legend: { color: string; code: string; minLogs: number },
  data: CountryPopularity[]
) {
  const { color, minLogs } = legend;

  data
    .sort((a, b) => b.logs - a.logs)
    .forEach(({ code, logs }) => {
      if (logs >= Number(minLogs)) {
        if (coloredCountries.find((c) => c.code === code)) return;
        const path = countries.find((c) => c.code === code)?.path;

        gsap.set(path, {
          fill: color,
        });

        coloredCountries.push({ code, color, path });
      }
    });
}
