import * as $ from "jquery";
import gsap from "gsap";
import { createPopper, Instance } from "@popperjs/core";
import { createMap } from "../../../modules/map/map";
import { makeDelay } from "../../../utilles/delay";

const hiddenStyle = { autoAlpha: 0, scale: 0 };
const shownStyle = { autoAlpha: 1, scale: 1 };
const duration = 0.3;

export async function gpsTooltip() {
  const { reinit } = (await init()) || {};
  $("[data-ajax-reinit-sensor]").on("change", reinit);

  async function init() {
    // Get root nodes
    const tooltip = $("[data-gps-tooltip]");
    if (!tooltip.length) return;
    const tooltipContent = tooltip.find("[data-tooltip-wrapper]");
    const target = $("[data-gps-tooltip-target]");

    // Get tooltip children nodes
    const countryImageNode = tooltipContent.find("[data-gps-country-image]");
    const countryNameNode = tooltipContent.find("[data-gps-country-name]");
    const addressNode = tooltipContent.find("[data-gps-address]");
    const latNode = tooltipContent.find("[data-gps-lat]");
    const lngNode = tooltipContent.find("[data-gps-lng]");

    // Prepare tooltip nodes
    target.css({ opacity: 0.5, cursor: "wait" });
    gsap.set(tooltipContent, hiddenStyle);

    // Init map
    const $map = tooltipContent.find("[data-map]");
    const map = await createMap({
      target: $map.get(0),
      config: {
        center: { lat: 0, lng: 0 },
        zoom: 10,
      },
    });

    // Add event listeners
    target.each(function () {
      this.addEventListener("click", show);
    });

    $("html").on("click", hide);

    // Set default state to button when map loaded
    target.css({ opacity: 1, cursor: "pointer" });

    // Prevent tooltip click propagation
    $(tooltipContent).on("click", (evt) => {
      evt.stopPropagation();
    });

    let popper: Instance | null = null;
    let currentTarget: HTMLElement | null = null;

    async function show(evt: MouseEvent) {
      evt.stopPropagation();

      const self = (evt.target as HTMLElement).closest(
        "[data-gps-tooltip-target]"
      ) as HTMLElement;

      // Prevent show tooltip twice
      if (currentTarget === self) return;
      await hide();

      tooltip.removeClass("hidden");
      await makeDelay(10);

      // Get tooltip refs
      const { lat, lng, address, countryIcon, countryName } = self.dataset;

      // Set text to tooltip
      countryImageNode.attr("src", countryIcon);
      countryNameNode.text(countryName);
      addressNode.text(address);
      latNode.text(lat);
      lngNode.text(lng);

      // Focus map
      map.setCenter({ lat, lng });

      // Init popper
      currentTarget = self;
      const { height } = self.getBoundingClientRect();

      popper = createPopper(self, tooltip.get(0), {
        placement: "bottom-start",
        modifiers: [
          {
            name: "flip",
            options: {
              fallbackPlacements: ["bottom-end", "top-end"],
              allowedAutoPlacements: ["bottom-end", "top-end"],
              rootBoundary: "document",
              padding: {
                top: $("header").height() + 30,
                right: 20,
                left: 20,
                bottom: 20,
              },
            },
          },
          {
            name: "offset",
            options: {
              offset() {
                return [5, -height + 5];
              },
            },
          },
        ],
      });

      // Animate tooltip
      gsap.to(tooltipContent, { ...shownStyle, duration });
    }

    function hide() {
      return new Promise((resolve) => {
        if (!popper) {
          return resolve("");
        }

        gsap.to(tooltipContent, {
          ...hiddenStyle,
          duration,
          onComplete() {
            popper?.destroy?.();
            currentTarget = null;
            tooltip.addClass("hidden");
            popper = null;
            resolve("");
          },
        });
      });
    }

    return {
      reinit: () => {
        // Add event listeners
        target.each(function () {
          this.removeEventListener("click", show);
        });

        $("[data-gps-tooltip-target]").each(function () {
          const self = $(this);
          this.addEventListener("click", show);
        });
      },
    };
  }
}
