import * as $ from "jquery";
import { IAppCtx } from "../../../app";
import { MODAL_DURATION } from "../modal";

export function initiModalModules(ctx: IAppCtx) {
  function setInputValue(input: HTMLElement, value: string | boolean) {
    const $input = $(input);

    if (
      input.tagName.toLowerCase() !== "input" &&
      input.tagName.toLowerCase() !== "textarea"
    ) {
      return $input.text(value).trigger("change").trigger("input");
    }

    if ($input.attr("type") === "checkbox") {
      return $input.prop({ checked: !!value }).trigger("change");
    }

    return $input
      .val(typeof value === "string" ? value : value.toString())
      .trigger("change")
      .trigger("input");
  }

  initAutofillModals();

  function initAutofillModals() {
    $("[data-autofill-modal]").each(function () {
      const modal = $(this);
      const id = modal.attr("id");

      const nodes = modal.find("[data-key]").toArray() as HTMLElement[];

      const onEmptyContent = modal.find("[data-on-empty-content]");
      const onHasContent = modal.find("[data-on-has-content]");

      const modalTitle = modal.find("[data-title]");
      const { defaultTitle = "" } = modalTitle.data() || {};

      let closeTimeout = null;

      ctx.modal.on(`#${id}`, "onBeforeClose", () => {
        clearTimeout(closeTimeout);

        closeTimeout = setTimeout(() => {
          nodes.forEach((node) => {
            setInputValue(node, "");
          });
        }, MODAL_DURATION);
      });

      ctx.modal.on(`#${id}`, "onShow", (target) => {
        clearTimeout(closeTimeout);
        const targetButton = $(target.closest("[data-modal-open]"));
        if (!targetButton) return;

        const modalContent = targetButton.data().modalContent as
          | { [key: string]: string | boolean }
          | undefined;

        // Set modal title
        const title = targetButton?.data()?.modalTitle as string;
        modalTitle.text(title ?? defaultTitle);

        // -- Set modal inputs data

        // On empty content
        if (!modalContent) {
          onEmptyContent.show();
          onHasContent.hide();

          return nodes.forEach((node) => {
            setInputValue(node, "");
          });
        }

        // On has content
        Object.entries(modalContent).forEach(([key, value]) => {
          onEmptyContent.hide();
          onHasContent.show();

          const node = nodes.find((n) => {
            return n.dataset.key === key;
          });

          if (!node) return;
          setInputValue(node, value);
        });
      });
    });
  }
}
