import $ from "jquery";

export function initDropdowns() {
  init();
  $("[data-ajax-reinit-sensor]").on("change", init);

  function init() {
    let dropdowns = [];

    $("[data-dropdown-trigger]").each(function () {
      let closed = true;

      const trigger = $(this);
      const dropdown = trigger.parents("[data-dropdown]");
      const id = dropdown.attr("id");
      const $triggerValue = trigger.find("[data-trigger-value]");
      const { target, multiselect, hideOnClick } = dropdown.data();
      const $filtersCountNode = dropdown.find("[data-filter-count]");
      const $target = $(target);
      const dropdownContent = trigger.siblings("[data-dropdown-content]");
      const $options = dropdownContent.find("[data-option]");
      const { hiddenClasses = "", activeClass = "" } = dropdownContent.data();

      $options.each(function () {
        const { value } = this.dataset;
        const self = $(this);

        self.on("click", () => {
          $target.val(value);
          $target.trigger("change");
          $triggerValue.text(value);

          $options.each(function () {
            $(this).removeClass(activeClass);
          });

          self.addClass(activeClass);
          if (multiselect === undefined || hideOnClick) {
            hide();
          }
        });
      });

      if ($options.length) {
        $target.on("change", ({ target }) => {
          $options.each(function () {
            $(this).toggleClass(activeClass, $(this).text() === target.value);
          });
        });
      }

      function hide() {
        dropdowns = dropdowns.filter((d) => {
          if (d.id === id) {
            d.hide();
          }

          return d.id !== id;
        });
      }

      function open() {
        closed = !closed;
        dropdownContent.toggleClass(hiddenClasses, closed);
        if (closed) {
          hide();
        } else {
          dropdowns.push({
            id,
            hide() {
              dropdownContent.addClass(hiddenClasses);
              closed = true;
            },
          });
        }
      }

      trigger.on("click", open);
      $target.on("click", open);

      if (hideOnClick) {
        dropdownContent.on("click", hide);
      }

      dropdown.find("[data-hidden-group]").each(function () {
        const self = $(this);
        const max = self.data().max;
        if (!max) return;

        const toggle = self.find("[data-hidden-toggle]");
        let hiddentCount = 0;
        const options = self.find("[data-option]");

        options.each(function (idx) {
          if (idx + 1 > max) {
            hiddentCount++;
            $(this).hide();
          }
        });

        if (hiddentCount > 0) {
          toggle.html(toggle.html().replace("{count}", hiddentCount));
          toggle.removeClass("hidden");

          toggle.on("click", () => {
            options.show();
            toggle.hide();
          });
        }
      });

      if (multiselect !== undefined) {
        let filterValue = $target.val() || "";

        const fields = dropdown.find("input[type='checkbox']");

        let filtersCount = fields.filter("[checked]").length;
        $filtersCountNode.text(filtersCount === 0 ? "" : `+${filtersCount}`);

        fields.each(function () {
          const input = $(this);

          input.on("change", () => {
            const id = input.attr("id");
            const checked = input.prop("checked");
            const name = input.attr("name");
            const query = `${name}:${id};`;

            if (!checked) {
              filterValue = filterValue.replace(query, "");
              filtersCount--;
            } else {
              filtersCount++;
              filterValue += query;
            }

            $target.val(filterValue);
            $filtersCountNode.text(
              filtersCount === 0 ? "" : `+${filtersCount}`
            );
            $target.trigger("change");
          });
        });
      }

      $(document).on("click", (evt) => {
        let target = evt.target.closest(".dropdown");
        let id = target?.getAttribute("id");

        if (!target) {
          target = evt.target.closest("[data-dropdown-select]");
          if (target) {
            const selectId = target?.getAttribute("id");
            id = $(`[data-target="#${selectId}"]`).attr("id");
          }
        }

        dropdowns.forEach((dropdown) => {
          if (dropdown.id === id) return;

          dropdown.hide();
          dropdowns = dropdowns.filter((d) => d.id !== dropdown.id);
        });
      });
    });
  }
}
