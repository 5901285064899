import "@/scss/app.scss";

import * as $ from "jquery";
import { switchTheme } from "./modules/switch-theme";
import { initDropdowns } from "./ui-components/dropdown";
import { tooltipInit } from "./ui-components/tooltip/tooltip";
import { copyButtons } from "./ui-components/copy-button";
import { initModal, Modal } from "./ui-components/modals/modal";
import { initCustomMap } from "./ui-components/custom-map/customMap";
import { initiSelectRows } from "./ui-components/table/select-rows";
import { initMaps } from "./modules/map/map";
import { initiModalModules } from "./ui-components/modals/modules/modalModules";
import { uploadFilesInit } from "./modules/file-upload";
import { CountryPopularity } from "./ui-components/custom-map/map.types";
import { initForm } from "./ui-components/form";

declare global {
  interface Window {
    modal: Modal;
    initMap: any;
    google: any;
    countryPopularity: CountryPopularity[];
  }
}
export interface IAppCtx {
  modal: Modal;
}
const ctx: Partial<IAppCtx> = {};

//  Use this file as endpoint.
//  Only for connect modules.
$(document).ready(function () {
  switchTheme();
  initForm();
  initDropdowns();
  tooltipInit();
  copyButtons();

  ctx.modal = initModal();

  initCustomMap();
  initiSelectRows();
  initMaps();

  initiModalModules(ctx as IAppCtx);
  uploadFilesInit();

  $("[data-ajax-reinit-sensor]").on("change", () => {
    ctx.modal = initModal();
    initiModalModules(ctx as IAppCtx);
  });

  $("[data-pass-switch]").on("click", function () {
    const input = $(this).siblings("input");
    const type = input.attr("type");
    input.attr("type", type === "password" ? "text" : "password");
  });
});
