import * as $ from "jquery";

export const fileRender = (file: File) => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = ({ target }) => {
      if (!target) {
        return reject("");
      }

      resolve(target.result);
    };

    reader.readAsDataURL(file);
  });
};

export function uploadFilesInit() {
  $("[data-upload-form]").each(function () {
    const uploader = $(this);

    const emptyNode = uploader.find("[data-uploader-empty]");
    const renderedNode = uploader.find("[data-uploader-rendered]");
    const renderedNodeImage = renderedNode.find("img");
    const input = uploader.siblings("input[type=file]");
    const imageSensor = uploader.find("[data-uploader-image-sensor]");

    if (imageSensor.length) {
      const initialPreview = (imageSensor.val() as string)?.trim();
      initialPreview ? onHavePreview(initialPreview) : onHaveNotPreview();

      imageSensor.on("change", () => {
        const initialPreview = (imageSensor.val() as string)?.trim();
        initialPreview ? onHavePreview(initialPreview) : onHaveNotPreview();
      });
    }

    const acceptedFiles = uploader
      .data("accepted")
      ?.split(",")
      ?.map((t) => t.trim());

    function onHavePreview(preview: string) {
      emptyNode.addClass("hidden");
      renderedNode.removeClass("hidden");
      renderedNodeImage.attr("src", preview);
    }

    function onHaveNotPreview() {
      emptyNode.removeClass("hidden");
      renderedNode.addClass("hidden");
      renderedNodeImage.attr("src", "");
    }

    const { activeClass, baseClass } = uploader.data() || {};

    async function onGetFile(file: File) {
      if (!acceptedFiles.includes(file.type)) return;

      const preview = (await fileRender(file)) as string;
      imageSensor.val(preview).trigger("change");

      // Your handler here
    }

    function dropHandler(evt: DragEvent) {
      evt.preventDefault();
      uploader.removeClass(activeClass);

      if (!uploader.hasClass(baseClass)) {
        uploader.addClass(baseClass);
      }

      const file = evt.dataTransfer.files?.[0] as File;
      if (!file) return;
      onGetFile(file);
    }

    function dragoverHandler(evt: DragEvent) {
      evt.preventDefault();
      uploader.addClass(activeClass);
      uploader.removeClass(baseClass);
    }

    function dragleaveHandler(evt: DragEvent) {
      evt.preventDefault();
      uploader.removeClass(activeClass);
      uploader.addClass(baseClass);
    }

    input.on("change", ({ target }) => {
      const file = (target as HTMLInputElement).files?.[0];

      if (file) {
        onGetFile(file);
      }
    });

    uploader.find("[data-uploader-remove]").on("click", onHaveNotPreview);
    uploader.get(0).addEventListener("drop", dropHandler);
    uploader.get(0).addEventListener("dragover", dragoverHandler);
    uploader.get(0).addEventListener("dragleave", dragleaveHandler);
  });
}
