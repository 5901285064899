const regs = [/[A-Z]/, /\d/, /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/];

function validate(value: string): number {
  let strength = 0;

  regs.forEach((r) => {
    if (r.test(value)) strength++;
  });

  if (value?.trim().length >= 8) strength++;
  return strength;
}

export function passwordStrength() {
  $("[data-password-strength]").each(function () {
    const root = $(this);
    const {
      passwordStrength: targetSelector,
      passwordStrengthGradations,
      activeClasses: activeClassesString,
      baseClass,
    } = root.data();

    const activeClasses = activeClassesString.split(",");
    const passwordStrengthGradationsArray =
      passwordStrengthGradations.split(",");

    const gradations = root
      .find(".password_strength__gradation")
      .toArray()
      .map((gradation) => {
        const node = $(gradation);
        return node;
      });

    const textKey = root.find("[data-strength-key]");
    const { defaultKey } = textKey.data();
    const target = $(targetSelector) as JQuery<HTMLInputElement>;

    const value = target.val() as string;
    value.length && strengthHandler(validate(value), value);

    target.on("input", () => {
      const value = target.val() as string;
      strengthHandler(validate(value), value);
    });

    function strengthHandler(strength: number, value: string) {
      const empty = value?.trim()?.length === 0;

      textKey.text(
        empty ? defaultKey : passwordStrengthGradationsArray[strength]
      );

      gradations.forEach((node, idx) => {
        const activeCls = empty ? baseClass : activeClasses[strength];

        activeClasses.forEach((cls) => node.removeClass(cls));

        if (idx <= strength) {
          node.removeClass(baseClass);
          return node.addClass(activeCls);
        }

        node.addClass(baseClass);
      });
    }
  });
}
