//import { formButtonDisable } from "./modules/form.button-disable";
// import { formValidate } from "./modules/form.validate";

import { initAutoGrowTextarea } from "./modules/form.autogrow-textarea";
import { passwordStrength } from "./modules/form.password-strength";

export function initForm() {
  // formButtonDisable();
  // formValidate();
  initAutoGrowTextarea();
  passwordStrength();
}
