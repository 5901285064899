import gsap from "gsap";

const startPosition = { y: 0, x: 0 }; // screen coord
const currentPosition = { ...startPosition };

export function handleMapMove(map: HTMLElement, container?: HTMLElement) {
  const root = container || map;

  const dragHandler = ({ clientX, clientY }) => {
    const x = clientX - startPosition.x;
    const y = clientY - startPosition.y;

    gsap.to(map, { x, y, duration: 0.2 });
    currentPosition.x = x;
    currentPosition.y = y;
  };

  root.addEventListener("mousedown", async ({ clientX, clientY }) => {
    startPosition.x = clientX - currentPosition.x;
    startPosition.y = clientY - currentPosition.y;
    document.documentElement.addEventListener("mousemove", dragHandler);
  });

  document.documentElement.addEventListener("mouseup", () => {
    document.documentElement.removeEventListener("mousemove", dragHandler);
    startPosition.x = 0;
    startPosition.y = 0;
  });
}
